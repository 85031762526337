<template>
  <div class="registrationWrapper" v-if="$route.params.canAccess">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-8">
          <div class="success-block text-center">
            <div class="success-image mb-4">
              <img
                src="@/assets/images/registration/success.svg"
                class="img-fluid"
              />
            </div>
            <h1>Thank you for Registration !</h1>
            <p>
              We have received your information and it is now up for review.
              <br />
              Please check your email for confirmation. <br />
              Thank you.
            </p>
            <div class="success-action">
              <router-link to="/">Home</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RegisterSuccess",
  created() {
    let canAccessPage = this.$route.params.canAccess;
    if (!canAccessPage) {
      this.$router.push({
        name: "Dashboard",
      });
    }
  },
};
</script>
<style src="@/assets/css/group-registration.css"></style>